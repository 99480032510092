import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, ChevronRight, Play, Globe, Languages, Tv, X, Zap, Shield, Check } from 'lucide-react';
import { Button } from "../../../ui/components/button";
import { Dialog, DialogContent, DialogClose, DialogTrigger } from "../../../ui/components/dialog";
import { Card, CardHeader, CardContent, CardDescription, CardFooter, CardTitle } from "../../../ui/components/card";
import { BorderBeam } from "../../../ui/components/border-beam";
import { BackgroundBeams } from "../../../ui/components/background-beams";
import Helmet from "react-helmet";

export default function TransLingo() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dialogOpened, setDialogOpened] = useState(false);

    const features = [
        {
            icon: <Languages className="size-8 text-primary" />,
            title: "15+ Language Support",
            description: "Translate instantly into over 15 different languages, from English and Japanese to Spanish and Mandarin."
        },
        {
            icon: <Tv className="size-8 text-primary" />,
            title: "Live Translation",
            description: "Enjoy real-time translation while you watch your favorite YouTube videos, without interruption."
        },
        {
            icon: <Zap className="size-8 text-primary" />,
            title: "Optimal performance",
            description: "Our advanced AI technology ensures fast, accurate translation with no impact on the quality of your stream."
        },
        {
            icon: <Shield className="size-8 text-primary" />,
            title: "Reliability & Security",
            description: "Your data is protected and our service is available 24/7 for a worry-free experience."
        }
    ];

    return(
        <>
        <Dialog open={dialogOpened} onOpenChange={setDialogOpened}>
        <DialogContent className="w-full flex h-screen border-none rounded-none items-start">
        <div className="relative size-full pt-[55%]">
            <iframe className="absolute top-0" width="100%" height="100%" src="https://www.youtube.com/embed/bVD4GksqKIE?autoplay=1" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        <DialogClose className="focus:outline-none outline-none">
            <Button>
            <X />
            </Button>
        </DialogClose>
        </DialogContent>
    </Dialog>
            <header className="sticky top-0 z-50 backdrop-blur-sm bg-background/80">
                <nav className="container mx-auto max-w-6xl py-5">
                    <div className="flex items-center justify-between">
                        <Link to={"/"}>
                            <div className="flex items-center space-x-2">
                                <Globe className="size-8 text-primary" />
                                <span className="font-bold text-xl">TransLingo</span>
                            </div>
                        </Link>

                        {/* Menu Desktop */}
                        <div className="hidden md:flex items-center space-x-4">
                            <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                                <Button size="default" variant="ghost">
                                    Contact
                                </Button>
                            </Link>
                            <Link to={"https://discord.gg/sps49AECCk"} target='about:blank'>
                                <Button size="default" variant="default">
                                    Join the Beta
                                </Button>
                            </Link>
                        </div>

                        <Button 
                            variant="ghost" 
                            className="md:hidden"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            <Menu />
                        </Button>
                    </div>

                    {isMenuOpen && (
                        <div className="md:hidden py-4 space-y-2">
                            <Link to={"https://discord.gg/sps49AECCk"} target='about:blank' className="block">
                                <Button size="default" variant="ghost" className="w-full">
                                    Contact
                                </Button>
                            </Link>
                            <Link to={"https://buy.stripe.com/9AQ4jmcBS6918r6007"} target='about:blank'>
                                <Button size="default" variant="default" className="w-full">
                                Join the Beta
                                </Button>
                            </Link>
                        </div>
                    )}
                </nav>
            </header>

            <section className="w-full rounded-md bg-background relative">
                <div className="mx-auto px-8 sm:px-6 lg:px-8 gap-5 pt-28 flex flex-col text-center max-w-7xl">
                    <h1 className="relative z-10 text-5xl md:text-6xl lg:text-7xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600 text-center font-sans font-bold">
                        Translate your YouTube videos in real time
                    </h1>
                    <p className="text-neutral-500 max-w-3xl mx-auto my-2 text-sm sm:text-md md:text-lg lg:text-xl text-center relative z-10">
                        Watch your favorite YouTube videos in over 15 languages with our AI-powered live audio translation.
                    </p>
                    <div className="flex flex-col items-center justify-center sm:flex-row z-10 mt-4 space-y-4 sm:space-y-0 sm:space-x-4">
                        <Link to={"https://buy.stripe.com/9AQ4jmcBS6918r6007"} target='about:blank'>
                            <Button className="h-12 font-semibold group cursor-pointer w-fit text-lg md:h-14 sm:px-10 rounded-md px-8">
                                <div className="flex items-center space-x-2">
                                    <span>Join the Beta</span>
                                    <ChevronRight size={20} strokeWidth={2.5} />
                                </div>
                            </Button>
                        </Link>
                        <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank" className="h-14 font-semibold flex items-center text-lg px-8">
                            Join the Community
                        </Link>
                    </div>
                </div>
                <BackgroundBeams className="z-0"/>
            </section>
            
            <section className="flex justify-center pt-20 pb-24">
                <div className="max-w-5xl rounded-xl relative hidden md:block">
                    <video width="2000" height="698" autoPlay muted playsInline loop poster="/posttrans.jpg" className="rounded-md border shadow-lg duration-500 animate-in fade-in">
                        <source src="/mrbest.mp4" type="video/mp4" />
                    </video>
                    <button
                        onClick={() => setDialogOpened(true)}
                        className="focus:outline-none outline-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 size-24 rounded-full sm:size-28 bg-primary/60 flex items-center justify-center backdrop-blur-sm transition-all duration-300 ease-in-out hover:scale-[0.8]"
                    >
                        <Play className="fill-white size-10"/>                
                    </button>
                    <BorderBeam />
                </div>
                <div className="relative rounded-md bg-background sm:mt-8 md:hidden px-10">
                    <video width="2000" height="698" autoPlay muted playsInline loop poster="/posttrans.jpg" className="rounded-md border shadow-lg duration-500 animate-in fade-in">
                        <source src="/mrbest.mp4" type="video/mp4" />
                    </video>
                </div>
            </section>
            
            <section className="py-24">
                <div className="container mx-auto max-w-7xl px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {features.map((feature, index) => (
                            <div key={index} className="p-6 rounded-lg border bg-background/50 backdrop-blur-sm">
                                <div className="mb-4">
                                    {feature.icon}
                                </div>
                                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                                <p className="text-neutral-500">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-16 bg-gray-50" id="pricing">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Pricing</h2>
          <p className="text-xl text-gray-600 mb-6">Join our waiting list to be among the first to try our revolutionary technology</p>
          <div className="inline-block bg-blue-100 text-blue-800 text-sm font-semibold px-4 py-2 rounded-full">Beta version - Limited seating</div>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
        <Card className={`flex flex-col`}>
              <CardHeader>
                <CardTitle>Waitlist</CardTitle>
                <CardDescription>You will be charged $19.99/months when TransLingo is officially released.</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-2xl font-bold mb-4">$19.99/months</p>
                <ul className="space-y-2">
                  {["Community discord"].map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center">
                      <Check className="text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Dialog>
                    <DialogTrigger className="w-full">
                        <Button className="w-full" variant="outline">
                            Join the WaitList
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                    <div id="getWaitlistContainer" data-waitlist_id="23190" data-widget_type="WIDGET_2" className="flex items-center justify-center"></div>
                    <Helmet>
                        <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
                        <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
                    </Helmet>
                    </DialogContent>
                </Dialog>
                
              </CardFooter>
            </Card>
            <Card className={`flex flex-col border-primary border-2`}>
              <CardHeader>
                <CardTitle>Beta</CardTitle>
                <CardDescription>Benefit from a discounted rate that will never increase</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-2xl font-bold mb-4">$9.99/months</p>
                <ul className="space-y-2">
                  {["Unlimited translation", "Access to TransLingo before its official release", "Private community with all new TransLingo advances", "Early access to new features"].map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center">
                      <Check className="text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Link to="https://buy.stripe.com/9AQ4jmcBS6918r6007" target="about:blank" className="w-full">
                    <Button className="w-full">
                    Join the Beta now
                    </Button>
                </Link>
              </CardFooter>
            </Card>
            <Card className={`flex flex-col`}>
              <CardHeader>
                <CardTitle>Entreprise</CardTitle>
                <CardDescription>Customized solutions for teams</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-2xl font-bold mb-4">On quotation</p>
                <ul className="space-y-2">
                  {["On ask"].map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center">
                      <Check className="text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <a href="mailto:contact@votre-email.com" className="w-full">
                <Button className="w-full" variant="outline">
                    Contact us
                </Button>
                </a>
              </CardFooter>
            </Card>
        </div>
      </div>
    </section>
    

        <footer className="container mx-auto px-10 py-12 max-w-7xl">
            <div className="flex flex-col sm:flex-row justify-between">
                <div className="space-y-2 text-muted-foreground">
                <div className="">
                    <img src="/lightlogoplasmara.png" className="h-8" />
                </div>
                <h3>
                    Join the community.
                </h3>
                <div className="flex space-x-4 pb-10">
                    <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank">
                        <svg fill="#7f8ea3" className="size-6" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z"/>
                        </svg>
                    </Link>
                    <Link to={"https://x.com/noshmaster"} target="about:blank">
                        <svg fill="#7f8ea3" className="size-6" width="800px" height="800px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.92,24.94A12.76,12.76,0,0,0,24.76,12.1c0-.2,0-.39,0-.59A9.4,9.4,0,0,0,27,9.18a9.31,9.31,0,0,1-2.59.71,4.56,4.56,0,0,0,2-2.5,8.89,8.89,0,0,1-2.86,1.1,4.52,4.52,0,0,0-7.7,4.11,12.79,12.79,0,0,1-9.3-4.71,4.51,4.51,0,0,0,1.4,6,4.47,4.47,0,0,1-2-.56v.05A4.53,4.53,0,0,0,9.5,17.83a4.53,4.53,0,0,1-2,.08A4.51,4.51,0,0,0,11.68,21,9.05,9.05,0,0,1,6.07,23,9.77,9.77,0,0,1,5,22.91a12.77,12.77,0,0,0,6.92,2" transform="translate(0)"/>
                        </svg>
                    </Link>
                    <Link to={"https://github.com/NoshMaster"} target="about:blank">
                        <svg fill="#7f8ea3" className="size-6" width="800px" height="800px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                            <g id="Dribbble-Light-Preview" transform="translate(-140.000000, -7559.000000)" fill="#7f8ea3">
                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                <path d="M94,7399 C99.523,7399 104,7403.59 104,7409.253 C104,7413.782 101.138,7417.624 97.167,7418.981 C96.66,7419.082 96.48,7418.762 96.48,7418.489 C96.48,7418.151 96.492,7417.047 96.492,7415.675 C96.492,7414.719 96.172,7414.095 95.813,7413.777 C98.04,7413.523 100.38,7412.656 100.38,7408.718 C100.38,7407.598 99.992,7406.684 99.35,7405.966 C99.454,7405.707 99.797,7404.664 99.252,7403.252 C99.252,7403.252 98.414,7402.977 96.505,7404.303 C95.706,7404.076 94.85,7403.962 94,7403.958 C93.15,7403.962 92.295,7404.076 91.497,7404.303 C89.586,7402.977 88.746,7403.252 88.746,7403.252 C88.203,7404.664 88.546,7405.707 88.649,7405.966 C88.01,7406.684 87.619,7407.598 87.619,7408.718 C87.619,7412.646 89.954,7413.526 92.175,7413.785 C91.889,7414.041 91.63,7414.493 91.54,7415.156 C90.97,7415.418 89.522,7415.871 88.63,7414.304 C88.63,7414.304 88.101,7413.319 87.097,7413.247 C87.097,7413.247 86.122,7413.234 87.029,7413.87 C87.029,7413.87 87.684,7414.185 88.139,7415.37 C88.139,7415.37 88.726,7417.2 91.508,7416.58 C91.513,7417.437 91.522,7418.245 91.522,7418.489 C91.522,7418.76 91.338,7419.077 90.839,7418.982 C86.865,7417.627 84,7413.783 84,7409.253 C84,7403.59 88.478,7399 94,7399" id="github-[#142]"></path>
                            </g>
                            </g>
                        </g>
                        </svg>
                    </Link>
                </div>
                <p className="text-sm">
                    © 2024 Plasmara. All rights reserved.
                </p>
                </div>
                <div className="flex gap-20">
                <div className="mt-10 flex basis-1/2 flex-col gap-5 sm:mt-0 sm:basis-auto">
                    <h3 className="text-lg font-semibold">
                        Community
                    </h3>
                    <Link to={"https://discord.com/invite/sps49AECCk"} target="about:blank" className="text-sm text-muted-foreground">
                        Discord
                    </Link>
                    <Link to={"https://x.com/noshmaster"} target="about:blank" className="text-sm text-muted-foreground">
                        Twitter
                    </Link>
                </div>
                <div className="mt-10 flex basis-1/2 flex-col gap-5 sm:mt-0 sm:basis-auto">
                    <h3 className="text-lg font-semibold">
                        Legal
                    </h3>
                    <Link to={"/terms"} className="text-sm text-muted-foreground">
                        Terms of Service
                    </Link>
                    <Link to={"/policy"} className="text-sm text-muted-foreground">
                        Privacy Policy
                    </Link>
                    <Link to={"/legal"} className="text-sm text-muted-foreground">
                        Legal
                    </Link>
                </div>
                </div>
            </div>
        </footer>
        </>
    );
}